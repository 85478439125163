import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Headroom from "react-headroom";
import {
  Flex,
  Spacer,
  Text,
  Stack,
  HStack,
  Box,
  useBreakpointValue,
  Link,
  Heading,
} from "@chakra-ui/react";
import DesktopNav from "../Navigation";
import StyleDrawer from "../StyleDrawer";
import PhoneIcon from "../Icons/PhoneIcon";
import SITE_CONFIG from "../../siteConfig";
import WebpImage from "../WebpImage";

const Header = () => {
  // console.log("current path " + useLocation().pathname);

  const [isSticky, setOnPin] = useState(true);
  // const [unPin, setUnPin] = useState(false);
  /* callback called when header position is no longer fixed */
  // const [isUnfix, setUnfix] = useState(false);

  /* get header's height */

  // const elmnt = document.getElementById("header-nav");
  // const navHeight = elmnt!.offsetHeight;
  // console.log(elmnt?.offsetHeight);

  // console.log("HEAD is pinned: " + isSticky);
  // console.log("HEAD is unpinned: " + unPin);
  // console.log("HEAD position is no longer fixed: " + isUnfix);
  const scrollTop = (e?: any) => {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const FirstYellow = ({ children }: any) => {
    return (
      <Heading as="h1" fontSize={14} fontFamily={"heading"} lineHeight="none">
        <Text color="yellow.400" as="span">
          {children.substring(0, 1)}
        </Text>
        {children.substring(1)}
      </Heading>
    );
  };
  var isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <Box as="header">
      <Headroom
        onPin={() => setOnPin(true)}
        onUnfix={() => setOnPin(false)}
        // onUnpin={() => setOnPin(true)}
        // onUnfix={() => setUnfix(true)}
        // wrapperStyle={{ marginBottom: '24px' }}
        style={{
          // backdropFilter: "saturate(180%) blur(20px)",
          background: isSticky ? "rgba(0, 0, 0, 0.87)" : "none",
          zIndex: 999,
        }}
      >
        <Box py={5} px={[5, 5, 7, "3%"]} transition="all 0.3s">
          <Flex width="100%" align="center">
            <NavLink className={"site-brand"} to={"/"} onClick={scrollTop}>
              <HStack spacing={3}>
                <WebpImage
                  isPNG
                  imgName="logo"
                  boxSize={14}
                  alt={`${SITE_CONFIG.SITE_NAME} logo image`}
                  borderRadius="sm"
                />
                <Stack
                  fontWeight="bold"
                  fontSize={"sm"}
                  lineHeight="none"
                  textTransform="uppercase"
                  color={"white"}
                  spacing="2%"
                  letterSpacing="1px"
                >
                  <FirstYellow>Christchurch</FirstYellow>
                  <FirstYellow>Constant</FirstYellow>
                  <FirstYellow>Construction Ltd</FirstYellow>
                </Stack>
              </HStack>
            </NavLink>
            <Spacer />
            {/* <Hamburger onClick={() => toggleClass()} /> */}
            {isMobile ? (
              <StyleDrawer />
            ) : (
              <>
                <DesktopNav />
                <Spacer />
                <HStack spacing={3} mr={2}>
                  <PhoneIcon boxSize={6} color={"white"} />
                  <Link
                    href="tel:+64210546463"
                    aria-label={`Call: ${SITE_CONFIG.CONTACT_NUMBER}`}
                    color={"white"}
                    textDecoration={"none"}
                    fontSize={"md"}
                    fontWeight="bold"
                    letterSpacing={"0.0125em"}
                  >
                    {SITE_CONFIG.CONTACT_NUMBER}
                  </Link>
                </HStack>
              </>
            )}
          </Flex>
        </Box>
      </Headroom>
    </Box>
  );
};

export default Header;
