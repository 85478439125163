import React from "react";
import {
  Box,
  Text,
  Container,
  Flex,
  Stack,
  AspectRatio,
  keyframes,
} from "@chakra-ui/react";
import { H2 } from "../../theme/ui-components/Typography";
import { sectionGap } from "./helper";
// import { Parallax } from "react-scroll-parallax";
import Reveal, { Fade } from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";
import WebpImage from "../../components/WebpImage";
import { Parallax } from "react-scroll-parallax";
import SITE_CONFIG from "../../siteConfig";

const fadingSlider = keyframes`
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AboutSection = () => {
  return (
    <Box position={"relative"} overflow='hidden'>
      <Box zIndex={1} position={"absolute"} bottom={"33%"} boxSize='full'>
        <Parallax speed={20}>
          <WebpImage
            isPNG
            imgName='ccc-logo-2pa-transparant'
            boxSize='full'
            objectFit='cover'
            alt='background image'
          />
        </Parallax>
      </Box>
      <Container id='about-us' py={sectionGap} position='relative' zIndex={2}>
        <Flex flexDirection={{ base: "column", md: "row" }} alignItems='center'>
          <Stack
            mb={5}
            width={{ md: "50%" }}
            // maxW='500px'
            // mr={{ md: 7 }}
            textAlign={{ md: "right" }}
          >
            <Fade cascade triggerOnce>
              <H2
                mb={{ lg: 6 }}
                position='relative'
                _before={{
                  content: '""',
                  bgColor: "yellow.400",
                  width: { base: 14 },
                  h: { base: 1, lg: 2 },
                  // mb: { base: 6, lg: 12 },
                  display: { base: "none", lg: "block" },
                  position: "absolute",
                  right: 0,
                  top: -10,
                }}
              >
                Meet
                <br />
                Christchurch
                <br />
                Constant
                <br />
                Construction
              </H2>
              <Text mb={4}>
                Our clients choose us because of the excellence of our
                workmanship and quality of service we provide. We are
                professional and reliable at all times. Our small friendly team
                are also highly experienced, with more than 10 years of
                experience in the landscaping industry. You will get all the
                expertise you need when you come to us. Our landscaping design
                services will give you an outside space that reflects your
                personality and your lifestyle. It will also make the most of
                the space you have, and it will help to increase the value of
                your property.
              </Text>
              <Text>
                To discuss the service you need, and to get a free,
                no-obligation quote, please leave your messsage below or give us
                a call on:
                <br />
                {SITE_CONFIG.CONTACT_NUMBER}
              </Text>
            </Fade>
          </Stack>
          <Box w={{ base: "full", md: "50%" }}>
            {/* <Fade duration={1200} delay={800}> */}
            <Reveal
              cascade
              keyframes={fadingSlider}
              duration={1200}
              className='animate image-wrapper'
              triggerOnce
            >
              <AspectRatio
                ratio={{ base: 1, md: 3 / 5, lg: 1 }}
                transform={{
                  base: "translateY(10%) scale(1.15)",
                  md: "translateX(10%) scale(1.15)",
                  lg: "translateX(33%) scale(1.325)",
                }}
              >
                {/* <Parallax speed={9}> */}

                <WebpImage
                  imgName='pic-about_us'
                  boxSize='full'
                  objectFit='cover'
                />

                {/* </Parallax> */}
              </AspectRatio>
            </Reveal>
            {/* </Fade> */}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AboutSection;
