import { SimpleGrid, SimpleGridProps } from "@chakra-ui/layout";
import * as React from "react";

export interface Props extends SimpleGridProps {
    backgroundColor?: string;
    extraClass?: string;
}
/**
 * A container is used at a page or component level to prevent content from becoming too wide.
 * This also centers the content when window exceeds the max-width.
 *
 * Content that uses the wrapper class will not exceed 1110px wide.
 *
 * @param props
 */
const GridLayout: React.FC<Props> = ({
    extraClass = "",
    backgroundColor,
    ...props
}) => (
    <SimpleGrid
        templateColumns={{
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
        }}
        // maxW='container.lg'
        // p={4}
        // m={{ base: 6, lg: "auto" }}
        gap={{ base: 4, md: 6 }}
        style={{ backgroundColor }}
        {...props}
    />
);

export default GridLayout;
