import { Box, Container } from "@chakra-ui/react";
import React from "react";
import Copyright from "./Copyright";

const Footer = () => {
  return (
    <Box
      as="footer"
      color="grey.100"
      bgColor="grey.400"
      borderTop="1px solid"
      borderColor="yellow.400"
    >
      <Container py={6}>
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
