import React, { useState } from "react";
import {
  Container,
  Stack,
  Button,
  Text,
  Box,
  VStack,
  Image,
  Center,
} from "@chakra-ui/react";
import { InputElementControl, TextareaControl } from "../../ui-form/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { H2 } from "../../theme/ui-components/Typography";
import WebpImage from "../../components/WebpImage";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import SITE_CONFIG from "../../siteConfig";

// const StackField = ({ ...props }: FlexProps) => (
//     <Flex
//         width='full'
//         flexDirection={{ base: "column", lg: "row" }}
//         sx={{
//             "> div": {
//                 mb: { base: 4, lg: 0 },
//                 mr: { lg: 4 },
//                 _last: {
//                     m: 0,
//                 },
//             },
//         }}
//         {...props}
//     />
// );

const LeadCaptureSchema = Yup.object().shape({
  from_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("your name is required"),
  from_email: Yup.string()
    .email("Please enter a valid email")
    .required("Email address is required"),
  mobilenumber: Yup.string()
    .min(7, "Too Short!")
    .max(16, "Too Long!")
    .required("Please enter a valid mobile number"),
  message: Yup.string()
    .min(2, "Too Short!")
    .required("Your message is required"),
});

const ContactSection = () => {
  const [captchaKey, setCaptchaKey] = useState(null);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const submitted = !errorSubmission && !successfulSubmission;
  const errorSubmissionMsg =
    "There was an issue with the submission, please try again or call us: " +
    SITE_CONFIG.CONTACT_NUMBER;
  return (
    <>
      <Box
        id="contact-us"
        position="relative"
        bgColor="black"
        // bgImage="url('images/reverse-bw-logo-transparant.png')"
        // bgSize="cover"
        // bgPos="center"
        py={[24, 36, 48]}
      >
        <WebpImage
          isPNG
          position={"absolute"}
          zIndex={1}
          bottom={0}
          imgName="reverse-bw-logo-transparant"
          boxSize="full"
          objectFit="cover"
          alt="background image"
        />
        <Box position="absolute" top={0} left={0} width="full" zIndex={2}>
          <Image
            src={`images/roof-shape.svg`}
            width="full"
            height="auto"
            alt="decorative-image"
          />
        </Box>
        <Container maxW="container.md" py={0} position="relative" zIndex={11}>
          <VStack
            // px={5}
            textAlign="center"
            // maxW='container.sm'
            mx="auto"
            mb={10}
            spacing={3}
            color="grey.100"
          >
            {/* <H3
                            textTransform={"uppercase"}
                            fontFamily='body'
                            fontSize='24px'
                        >
                            Contact Us
                        </H3> */}
            <H2 color={"white"}>
              {!successfulSubmission ? (
                <>
                  <Box as="span" color="yellow.400" fontWeight="bold">
                    Let's talk{" "}
                  </Box>
                  about your landscaping plan
                </>
              ) : (
                "Message Sent"
              )}
            </H2>
            <Text>
              {successfulSubmission
                ? "Thank you for contacting us! We have received your message and will be in touch. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare sed arcu, arcu morbi non urna..."
                : "We'd love to help you, give us a call, leave us a message below or send us through an email and we'll be touch soon to discuss your ideas."}
            </Text>
          </VStack>
          {successfulSubmission ? (
            <Center>
              <WebpImage isPNG imgName="mail-sent" alt="message sent" />
            </Center>
          ) : (
            <Formik
              initialValues={{
                from_name: "",
                from_email: "",
                mobilenumber: "",
                subject: "",
                message: "",
              }}
              validationSchema={LeadCaptureSchema}
              onSubmit={(values) => {
                const templateParams = {
                  ...values,
                  "g-recaptcha-response": captchaKey,
                };
                console.log(templateParams);
                send(
                  SITE_CONFIG.EMAILJS_serviceID,
                  SITE_CONFIG.EMAILJS_templateID,
                  templateParams,
                  SITE_CONFIG.EMAILJS_userID
                )
                  .then((response) => {
                    setSuccessfulSubmission(true);
                    console.log("SUCCESS!", response.status, response.text);
                  })
                  .catch((err) => {
                    setErrorSubmission(true);
                    console.log("FAILED...", err);
                  });
              }}
            >
              {({
                // values,
                dirty,
                isValid,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={4} alignItems="flex-start">
                    <InputElementControl
                      type="text"
                      name="from_name"
                      label="Name"
                      isRequired
                    />
                    <InputElementControl
                      type="email"
                      name="from_email"
                      label="Email"
                      isRequired
                    />
                    <InputElementControl
                      name="mobilenumber"
                      label="contact number"
                      isRequired
                    />
                    <InputElementControl
                      type="text"
                      name="subject"
                      label="subject"
                    />
                    <TextareaControl
                      name="message"
                      placeholder="Leave us a message"
                      isRequired
                      _placeholder={{
                        color: "#a0a0a0",
                      }}
                    />
                    <ReCAPTCHA
                      theme="dark"
                      sitekey={SITE_CONFIG.RECAPTCHA_SITE_KEY}
                      onChange={setCaptchaKey}
                    />
                    {!(dirty && isValid && captchaKey !== null) && (
                      <Text color="grey.300" fontSize="sm" noOfLines={2}>
                        * Please complete the above required fields and check
                        thereCAPTCHA box in order to submit the form.
                      </Text>
                    )}
                    {errorSubmission && (
                      <Text color="red.400" fontSize="sm" noOfLines={2}>
                        {errorSubmissionMsg}
                      </Text>
                    )}
                  </Stack>
                  <Button
                    type="submit"
                    colorScheme="yellow"
                    mt={6}
                    h="64px"
                    w={{ base: "full", lg: "unset" }}
                    fontSize={"sm"}
                    letterSpacing={"1.5px"}
                    textTransform={"uppercase"}
                    isLoading={isSubmitting && submitted}
                    disabled={
                      (!(dirty && isValid) ||
                        isSubmitting ||
                        captchaKey === null) &&
                      submitted
                    }
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </Box>
    </>
  );
};
export default ContactSection;
