import { getColor, mode } from "@chakra-ui/theme-tools";

const parts = ["field", "addon"];
type Dict = Record<string, any>;

function baseStyle(props: Dict) {
    const { colorScheme: c } = props;
    return {
        bg: mode(`${c}.200`, `${c}.100`)(props),
        minWidth: 0,
        outline: 0,
        position: "relative",
        appearance: "none",
        transition: "all 0.2s",
    };
}

const size = {
    md: {
        fontSize: "md",
        borderRadius: "sm",
        px: 4,
        h: "60px",
    },
};

const sizes = {
    md: {
        field: size.md,
        addon: size.md,
    },
};

function getDefaults(props: Record<string, any>) {
    const { focusBorderColor: fc, errorBorderColor: ec } = props;
    const { colorScheme: c } = props;
    return {
        focusBorderColor: fc || mode(`${c}.500`, `${c}.300`)(props),
        errorBorderColor: ec || mode("red.400", "red.500")(props),
    };
}

function variantFilled(props: Record<string, any>) {
    const { theme } = props;
    const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
    const { colorScheme: c } = props;
    return {
        field: {
            width: "100%",
            border: "2px solid",
            borderColor: "transparent",
            color: "grey.100",
            bg: mode(`${c}.300`, `whiteAlpha.70`)(props),
            _hover: {
                color: "white",
                bg: mode(`${c}.300`, `whiteAlpha.100`)(props),
                borderColor: `${c}.200`,
            },
            _readOnly: {
                boxShadow: "none !important",
                userSelect: "all",
            },
            _disabled: {
                bg: "grey.100",
                cursor: "not-allowed",
            },
            _invalid: {
                borderColor: getColor(theme, ec),
                color: getColor(theme, ec),
            },
            _focus: {
                color: "white",
                bg: mode(`${c}.200`, `whiteAlpha.100`)(props),
                borderColor: getColor(theme, fc),
                _placeholder: {
                    opacity: "0",
                },
            },
        },
        addon: {
            border: "2px solid",
            borderColor: "transparent",
            bg: mode("gray.100", "whiteAlpha.50")(props),
        },
    };
}
function variantOutline(props: Record<string, any>) {
    return {
        field: {
            border: "1px solid",
            borderColor: "white",
            color: "grey",
            bg: mode("transparent", "whiteAlpha.50")(props),
            _hover: {
                bg: mode("black.300", "whiteAlpha.70")(props),
            },
            _focus: {
                background: "black.100",
                _placeholder: {
                    opacity: "0",
                },
            },
        },
    };
}

function variantSorted(props: Record<string, any>) {
    return {
        field: {
            border: "2px solid",
            borderColor: "transparent",
            color: "deepblue.500",
            bg: mode("transparent", "whiteAlpha.50")(props),
            _hover: {
                bg: mode("deepblue.100", "whiteAlpha.100")(props),
                color: "deepblue.500",
            },
            _focus: {
                background: "deepblue.100",
                borderColor: "transparent",
                _placeholder: {
                    opacity: "0",
                },
            },
        },
    };
}

const variantUnstyled = {
    field: {
        bg: "transparent",
        px: 0,
        height: "auto",
        _hover: {
            bg: "transparent",
            color: "inherit",
        },
        _focus: {
            bg: "transparent",
            color: "inherit",
        },
    },
    addon: {
        bg: "transparent",
        px: 0,
        height: "auto",
    },
};
const variants = {
    filled: variantFilled,
    sorted: variantSorted,
    outline: variantOutline,
    unstyled: variantUnstyled,
};

const defaultProps = {
    size: "sm",
    variant: "filled",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    parts,
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
