import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Footer from "./components/Footer";

import "@fontsource/mulish";
import "@fontsource/montserrat/700.css";

import GoTopButton from "./components/GoTopButton";
import Home from "./pages/Home";
import Header from "./components/Header";
// import Header from "./components/Header";
// import { Global } from "@emotion/react";

const App = () => {
  /* 
  display loading screen 
  */
  // const [isLoading, setLoading] = React.useState(true);
  // const fakeRequest = () => {
  //   return new Promise<void>((resolve) => setTimeout(() => resolve(), 500));
  // };
  // React.useEffect(() => {
  //   fakeRequest().then(() => {
  //     const el = document.querySelector(".loader-container");
  //     if (el) {
  //       el.classList.add("fade-out");
  //       /* remove the element after 0.5s */
  //       setTimeout(function () {
  //         el.remove();
  //         document.body.classList.remove("no-scroll");
  //         document.body.removeAttribute("class");
  //       }, 500);
  //       setLoading(!isLoading);
  //     }
  //   });
  // }, [isLoading]);
  // if (isLoading) {
  //   return null;
  // }

  return (
    <>
      <ChakraProvider theme={theme}>
        <Header />
        <Home />
        <Footer />
        <GoTopButton />
      </ChakraProvider>
    </>
  );
};

export default App;
