import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Box,
  List,
  ListItem,
  Link,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import SITE_CONFIG from "../../siteConfig";
import LOOP from "../Navigation/navitems.json";
import HamburgerMenuIcon from "../Icons/HamburgerMenuIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import EmailIcon from "../Icons/EmailIcon";

export default function StyleDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const delayClose = () => {
    setTimeout(onClose, 1000);
  };
  return (
    <>
      <IconButton
        border='none'
        boxSize={10}
        display='flex'
        justifyContent='center'
        alignItems='center'
        aria-label='open navigation'
        bg='transparent'
        icon={<HamburgerMenuIcon w={6} h={4} />}
        onClick={onOpen}
        _hover={{
          cursor: "pointer",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
      />

      <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton
              fontSize={"22px"}
              size={"lg"}
              top={8}
              right={6}
            />
            <DrawerHeader />
            <DrawerBody>
              <Box h='full' display='flex' alignItems='center'>
                <Box as='nav' className='mobile-nav' pl={4}>
                  <List spacing={2}>
                    {LOOP.map((data: any) => (
                      <ListItem key={data.menuItem.toString()}>
                        <Link
                          href={`#${data.menuItem.split(" ").join("-")}`}
                          textDecoration='none'
                          textTransform='uppercase'
                          // textDecor={'underline'}
                          fontFamily={"heading"}
                          fontSize='28px'
                          onClick={delayClose}
                        >
                          {data.menuItem}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                  <Stack spacing={4} mt={14} fontSize='md'>
                    <Link
                      href='tel:+64210546463'
                      aria-label={`Call: ${SITE_CONFIG.CONTACT_NUMBER}`}
                    >
                      <PhoneIcon boxSize={5} color={"black"} mr={3} />
                      {SITE_CONFIG.CONTACT_NUMBER}
                    </Link>
                    <Link href={`mailto:${SITE_CONFIG.EMAIL}`}>
                      <EmailIcon boxSize={5} color={"black"} mr={3} />
                      {SITE_CONFIG.EMAIL}
                    </Link>
                  </Stack>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}
