import React from "react";
import { Box, Text, Container, Stack } from "@chakra-ui/react";
import { QuoteIcon } from "../../theme/ui-components/icons";
// import { H4 } from "../../theme/ui-components/Typography";
// import { sectionGap } from "./helper";
import { Fade } from "react-awesome-reveal";

const MoreSection = () => {
    return (
        <Box
            position={"relative"}
            bgColor='grey.400'
            color='white'
            py={[12, 20]}
        >
            <Container maxW='container.md' id='more'>
                <Stack
                    maxW={{ base: "container.md", xl: "container.lg" }}
                    mx='auto'
                    textAlign='center'
                    spacing={6}
                >
                    <Fade cascade duration={500} triggerOnce>
                        <QuoteIcon boxSize={8} />
                        <Text fontSize='22px' fontWeight='bold'>
                            Our expertise and passion is designing and
                            constructing outdoor spaces that add value to your
                            property and lifestyle.
                        </Text>
                        {/* <QuoteIcon boxSize={8} transform='rotate(180deg)' /> */}
                    </Fade>
                </Stack>
            </Container>
        </Box>
    );
};

export default MoreSection;
