import React from "react";
import { Box, Link } from "@chakra-ui/react";
import SITE_CONFIG from "../../siteConfig";

// const thisYear = new Date().getFullYear();

const Copyright = () => {
  return (
    <Box
      fontSize="xs"
      fontWeight="bold"
      textAlign="center"
      textTransform="uppercase"
      letterSpacing="0.54px"
    >
      &copy; {new Date().getFullYear()} {SITE_CONFIG.SITE_NAME}
      <Box as="span" mx="2" fontSize="xs" opacity="0.54">
        |
      </Box>
      powered by{" "}
      <Link color="yellow.400" href="http://archieweb.nz" isExternal>
        ArchieWeb
      </Link>
    </Box>
  );
};

export default Copyright;
