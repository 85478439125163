import React, { useState } from "react";
import { Flex } from "@chakra-ui/layout";
import { ArrowIcon } from "../../theme/ui-components/icons";

const GoTopButton = () => {
  const [isActive, setActive] = useState(false);
  /* 定義與取得數值 */
  // var CurrentY = document.documentElement.scrollTop;
  /* Get the entire height of an element, including padding */
  // var docHeight = document.documentElement.scrollHeight;
  // var winHeight = window.innerHeight;
  // var scrollPercent = (docHeight / CurrentY) * 100;
  // var scrollPercentRounded = Math.round(winHeight / 4);

  // console.log(CurrentY, docHeight, winHeight / 2);

  /* when window scroll執行以下 */
  window.onscroll = () => {
    scrollFunction();
  };
  /* 當可scroll超過300以上才執行以下 */
  const scrollFunction = () => {
    document.documentElement.scrollTop > 300
      ? setActive(true)
      : setActive(false);
  };
  /* 按下go top的按鈕後回到最上層 */
  const ScrolltoTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <>
      <Flex
        aria-label="go top button"
        role="button"
        position="fixed"
        bgColor="black"
        color="white"
        align="center"
        justifyContent="center"
        w={16}
        h={16}
        fontSize="sm"
        zIndex="99"
        cursor="pointer"
        borderRadius="full"
        textAlign="center"
        bottom={{ base: "1.25em", md: "24px" }}
        right={{ base: "1em", md: "24px" }}
        opacity={0}
        border="none"
        transition="all 0.31s"
        onClick={() => {
          ScrolltoTop();
        }}
        title="Go to top button"
        className={"go-top" + (isActive ? " active" : "")}
        sx={{
          "&.active": {
            opacity: "0.7",
          },
        }}
      >
        <ArrowIcon boxSize={6} transform="rotate(-90deg)" />
      </Flex>
    </>
  );
};

export default GoTopButton;
