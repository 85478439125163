import React from "react";
import { Box, Image } from "@chakra-ui/react";

interface TriangleProps {
    reverse?: Boolean;
}

const sectionGap = { base: 20, md: 24, lg: 36, xl: 48 };

const TriangleShape = ({ reverse }: TriangleProps) => {
    return (
        <Box
            position='absolute'
            top={reverse ? "-1px" : "unset"}
            bottom={reverse ? "unset" : "-1px"}
            left={0}
            width='full'
        >
            <Image
                src={`images/triangle-shape.svg`}
                transform={reverse ? "scale(-1)" : "unset"}
                width='full'
                height='auto'
                alt='triangle-shape-image'
            />
        </Box>
    );
};

export { sectionGap, TriangleShape };
