const colors = {
    transparent: "transparent",
    grey: {
        100: "#D7D7D7",
        200: "#9ca3af",
        300: "#757575",
        400: "#333533",
        450: "#4d4d4d",
        500: "#6A7587",
    },
    black: {
        100: "#D7D7D7",
        200: "#757575",
        300: "#333333",
        400: "#1c1c1c",
        500: "#000000",
    },
    deepblue: {
        10: "#F8F8F9",
        50: "#F3F4F5",
        100: "#E6E8EB",
        200: "#CDD1D7",
        300: "#6A7587",
        400: "#EEF4F4",
        500: "#071937",
        600: "#040F20",
    },
    yellow: {
        400: "#FFEB00",
        500: "#FFD500",
    },
    red: {
        50: "#FFF4F6",
        400: "#DF3550",
    },
    orange: {
        50: "#FCEEEA",
        500: "#DF532D",
        600: "#D24620",
    },
    green: {
        400: "#4F772D",
        500: "#31572C",
    },
    teal: {
        100: "#EEF4F4",
        200: "#DDEAEA",
        300: "#90D0D0",
        500: "#22a2a2",
        600: "#209898",
        700: "#1E8D8D",
    },
};
const aliases = {
    primary: colors.yellow[400],
    secondary: colors.green[400],
    tertiary: colors.orange[500],
    success: colors.green[400],
    error: colors.orange[500],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...colors,
    ...aliases,
};
