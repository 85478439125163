import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import AboutSection from "./AboutSection";
import MoreSection from "./MoreSection";
import ServicesSection from "./ServicesSection";
import WorksSection from "./WorksSection";
import HeroSection from "./HeroSection";
import ContactSection from "./ContactSection";
const Home = () => {
  return (
    <main>
      <ParallaxProvider>
        <HeroSection />
        <ServicesSection />
        <WorksSection />
        <AboutSection />
        <MoreSection />
        <ContactSection />
      </ParallaxProvider>
    </main>
  );
};
export default Home;
