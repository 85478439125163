import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";
import LazyLoad from "react-lazyload";
import SITE_CONFIG from "../../siteConfig";

export interface WebpProps extends ImageProps {
  imgName: string;
  isPNG?: boolean;
}

const WebpImage = ({ imgName, isPNG, ...props }: WebpProps) => {
  return (
    <LazyLoad offset={200} height={200} once>
      <picture>
        <source
          srcSet={`images/${imgName}${isPNG ? "_lossless.webp" : ".webp"}`}
          type='image/webp'
        />
        <Image
          src={`images/${imgName}${isPNG ? ".png" : ".jpg"}`}
          alt={`image of ${imgName} - ${SITE_CONFIG.SITE_NAME} `}
          {...props}
        />
      </picture>
    </LazyLoad>
  );
};

export default WebpImage;
