import React from "react";
import {
    Box,
    Text,
    Container,
    Flex,
    Stack,
    VStack,
    AspectRatio,
    useBreakpointValue,
} from "@chakra-ui/react";
import { H2 } from "../../theme/ui-components/Typography";
import { sectionGap, TriangleShape } from "./helper";
import { Fade } from "react-awesome-reveal";
import { Parallax } from "react-scroll-parallax";
import WebpImage from "../../components/WebpImage";

const WorksSection = () => {
    const imageGap = [4, 5, 6, 7];
    const isLarge = useBreakpointValue({ base: false, md: true });
    const fadingImages = (
        <Stack
            spacing={imageGap}
            mb={imageGap}
            // width={{ md: "50%" }}
        >
            <Fade cascade triggerOnce>
                <Box pl={{ md: "20%" }}>
                    <AspectRatio ratio={6 / 4}>
                        <WebpImage
                            imgName='example3'
                            boxSize='full'
                            objectFit='cover'
                        />
                    </AspectRatio>
                </Box>
                <WebpImage imgName='example' boxSize='full' />
            </Fade>
        </Stack>
    );

    return (
        <Box
            id='our-works'
            position='relative'
            py={sectionGap}
            bgImage="url('images/bg-brick.webp'), url('images/bg-brick.jpg')"
            bgAttachment='fixed'
            bgPos='center'
            // bgSize='cover'
            color='white'
        >
            {/* <WebpImage
        zIndex={1}
        position="absolute"
        top={0}
        imgName="bg-brick"
        boxSize="full"
        objectFit="cover"
        alt="background image"
      /> */}
            <TriangleShape reverse />
            <VStack
                px={5}
                maxW='container.md'
                textAlign='center'
                mx='auto'
                position='relative'
                zIndex={11}
            >
                <Fade cascade triggerOnce>
                    <H2>our works</H2>
                    <Text>
                        We offer a full range of landscaping services in
                        the Canterbury region. We provide landscape construction
                        services for all features and structures common in
                        gardens and outside spaces. This includes everything
                        from raised beds, fences, and decking to pergolas, water
                        features, and paths.
                    </Text>
                </Fade>
            </VStack>
            <Container position='relative' zIndex={11}>
                <Flex flexDirection={{ base: "column", md: "row" }}>
                    {isLarge ? (
                        <Parallax speed={-8} style={{ width: "50%" }}>
                            {fadingImages}
                        </Parallax>
                    ) : (
                        fadingImages
                    )}

                    <Stack
                        spacing={imageGap}
                        pl={[0, 0, 6, 7]}
                        width={{ md: "50%" }}
                    >
                        <Fade cascade triggerOnce>
                            {/* <AspectRatio ratio={{ base: 6 / 4, md: 1 }}> */}
                            <WebpImage
                                imgName='example2'
                                boxSize='full'
                                objectFit='cover'
                            />
                            {/* </AspectRatio> */}
                            <Box pr={{ md: "18%" }}>
                                <AspectRatio ratio={6 / 4}>
                                    <WebpImage
                                        imgName='example4'
                                        boxSize='full'
                                        objectFit='cover'
                                    />
                                </AspectRatio>
                            </Box>
                        </Fade>
                    </Stack>
                </Flex>
            </Container>
            <TriangleShape />
        </Box>
    );
};

export default WorksSection;
