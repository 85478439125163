import React from "react";
import { Box, Text, Container, Stack, keyframes } from "@chakra-ui/react";
import { Headline } from "../../theme/ui-components/Typography";
import { Parallax } from "react-scroll-parallax";
import Reveal from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";
import SITE_CONFIG from "../../siteConfig";

const fadingSlider = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const HeroSection = () => {
  return (
    <Box
      className='hero-image'
      bgImage="url('images/bg-landing.webp'), url('images/bg-landing.jpg')"
      bgAttachment={"fixed"}
      bgSize={{ base: "auto", lg: "cover" }}
      bgPos={{ base: "bottom center", lg: "bottom left" }}
      height='92vh'
      display='flex'
      alignItems='center'
      // transform='translateY(-96px)'
      mt='-96px'
      pos='relative'
      zIndex={1}
      _before={{
        content: '""',
        bgColor: { base: "rgba(0,0,0,0.54)", lg: "rgba(0,0,0,0.38)" },
        boxSize: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        display: "block",
        zIndex: 1,
      }}
      _after={{
        content: '""',
        bgColor: "rgba(18,18,18,0.65)",
        width: { lg: "45%" },
        display: { base: "none", md: "block" },
        height: "full",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <Container color='white' pos='relative' zIndex={9} py={0}>
        <Parallax speed={-10}>
          <Stack
            maxW='560px'
            spacing={{ base: 2, lg: 1 }}
            alignItems={{ lg: "flex-end" }}
            textAlign={{ lg: "right" }}
          >
            <Reveal
              cascade
              keyframes={fadingSlider}
              duration={1200}
              triggerOnce
            >
              <Text as='span' fontSize='md'>
                {SITE_CONFIG.SITE_NAME}
              </Text>
              <Headline>we exist to</Headline>
              <Headline>build great</Headline>
              <Headline>things</Headline>
              <Text
                fontSize={{ base: 28, lg: 36 }}
                pt={2}
                color='yellow.400'
                fontFamily={"heading"}
                lineHeight='none'
              >
                in Christchurch
              </Text>
            </Reveal>
          </Stack>
        </Parallax>
      </Container>
    </Box>
  );
};

export default HeroSection;
