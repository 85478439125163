const baseStyle = {
    maxW: { base: "container.lg", xl: "1200px" },
    py: [6, 8, 12, 16],
    px: [5, 5, 6, 6, 0],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    baseStyle,
};
