import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
// Global style overrides
import styles from "./styles";
import colors from "./colors";
// Foundational style overrides
// import borders from "./foundations/borders";
// Component style overrides
import Button from "./button";
import Checkbox from "./checkbox";
import Radio from "./radio";
import Switch from "./switch";
import Input from "./input";
import Select from "./select";
import Textarea from "./textarea";
import Link from "./link";
import Container from "./container";
import sizes from "./sizes";
import fonts from "./fonts";

// 2. Add your color mode config
// const config: any = {
//   initialColorMode: "dark",
//   useSystemColorMode: true,
// };

export default extendTheme(
  {
    // initialColorMode: "dark",
    useSystemColorMode: true,
    styles,
    colors,
    fonts,
    sizes,
    // Other foundational style overrides go here
    components: {
      Checkbox,
      Button,
      Radio,
      Switch,
      Link,
      Input,
      Select,
      Textarea,
      Container,
    },
  },
  withDefaultColorScheme({ colorScheme: "black" })
);
