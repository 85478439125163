import { createIcon } from "@chakra-ui/react";
import React, { Fragment } from "react";

export const HamburgerMenuIcon = createIcon({
    displayName: "HamburgerMenuIcon",
    viewBox: "0 0 30 18",
    path: (
        <>
            <rect width='30' height='1' rx='1' y='0' fill='currentColor' />
            <rect width='30' height='1' rx='1' y='8' fill='currentColor' />
            <rect width='30' height='1' rx='1' y='16' fill='currentColor' />
        </>
    ),
});

export default HamburgerMenuIcon;
