import { Box, Link, List, ListItem } from "@chakra-ui/react";
import React from "react";
import LOOP from "./navitems.json";

const DesktopNav = () => {
  return (
    <Box
      as="nav"
      className="deskop-menu"
      display={{ base: "none", md: "block" }}
    >
      <List display="flex">
        {LOOP.map((data: any) => (
          <ListItem key={data.menuItem.toString()}>
            <Link
              href={`#${data.menuItem.split(" ").join("-")}`}
              textDecoration="none"
              textTransform="uppercase"
              color="white"
              px={{ base: 4, xl: 6 }}
              fontWeight="700"
              fontSize={"sm"}
              letterSpacing={"0.0825em"}
              _hover={{
                color: "yellow.400",
              }}
            >
              {data.menuItem}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
export default DesktopNav;
