import React from "react";
import {
  Box,
  Text,
  Container,
  VStack,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import { H2, H3 } from "../../theme/ui-components/Typography";
import { sectionGap } from "./helper";
import serviceList from "./services.json";
import SITE_CONFIG from "../../siteConfig";
import GridLayout from "../../components/GridLayout";
import { Parallax } from "react-scroll-parallax";
import { Fade } from "react-awesome-reveal";
import WebpImage from "../../components/WebpImage";

const ServicesSection = () => {
  return (
    <Container
      id='services'
      pos='relative'
      py={sectionGap}
      // _after={{
      //     content: '""',
      //     bgColor: "yellow.400",
      //     bgImage: "url('images/logo-mark.png')",
      //     bgSize: "contain",
      //     boxSize: { base: "200px", md: "300px" },
      //     position: "absolute",
      //     borderRadius: "sm",
      //     top: -20,
      //     left: { base: 0, md: "10%" },
      //     display: "block",
      //     zIndex: 1,
      // }}
    >
      <Box
        position={"absolute"}
        top='-5%'
        left={{ base: 0, md: "10%" }}
        display='block'
        zIndex={1}
      >
        <Parallax speed={8}>
          <WebpImage
            isPNG
            imgName='logo-mark'
            boxSize={{ base: "200px", md: "300px" }}
            borderRadius='sm'
          />
        </Parallax>
      </Box>
      <VStack
        mb={12}
        pos='relative'
        textAlign={"center"}
        maxW='container.md'
        mx='auto'
        zIndex={2}
      >
        <Fade triggerOnce>
          <H2>our services</H2>
          <Text>
            Best A Grade Commercial and Residential Services. We provide all
            types of earth works, retaining wall constructions, footpaths and
            patios, drain laying, sand traps, driveway sumps and more.
          </Text>
        </Fade>
      </VStack>
      <GridLayout>
        <Fade triggerOnce>
          {serviceList.map((item: any, index: number) => (
            <GridItem key={index}>
              <Stack mb={[6, 6, 8, 10]}>
                {/* <AspectRatio ratio={{ base: 6 / 4, md: 1 }}> */}
                <Box
                  className='image-wrapper'
                  mb={4}
                  borderRadius='sm'
                  overflow={"hidden"}
                >
                  <Parallax scale={[1, 1.15]}>
                    <WebpImage
                      imgName={`pic-${item.picture}`}
                      alt={`${item.picture} service picture ${SITE_CONFIG.SITE_NAME}`}
                      boxSize={{ base: "100%", md: "385px" }}
                      // objectFit='cover'
                      // mb={4}
                      // borderRadius='sm'
                    />
                  </Parallax>
                </Box>
                {/* </AspectRatio> */}
                <H3 textTransform={"capitalize"}>{item.title}</H3>
                <Text color='grey.300'>{item.intro}</Text>
              </Stack>
            </GridItem>
          ))}
        </Fade>
      </GridLayout>
    </Container>
  );
};

export default ServicesSection;
