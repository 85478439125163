// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    // body: {
    //   bgColor: "inherit",
  },
};
