const SITE_CONFIG = {
    SITE_NAME: "Christchurch Constant Construction Ltd",
    SITE_ROOT_PATH: "/",
    CONTACT_NUMBER: "021 054 6463",
    EMAIL: "tonyfu609@hotmail.com",
    RECAPTCHA_SITE_KEY: "6Ld0ZaQeAAAAAJDTqMAdJC8ui6QTJHZ5svRU1EXW",
    EMAILJS_serviceID: "service_mailJS",
    EMAILJS_templateID: "emailjs-contact-form",
    EMAILJS_userID: "VMrS4k8mtIddnc_Dc",
};

export default SITE_CONFIG;
