import { ErrorMessage, useField } from "formik";
import React, { ReactNode } from "react";
import {
    Textarea,
    TextareaProps,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
} from "@chakra-ui/react";

export type TextareaControlProps = Omit<FormControlProps, "label"> &
    TextareaProps & {
        name: string;
        helpText?: string;
        children?: ReactNode;
        extendedText?: {
            label: string;
            body: string | ReactNode;
        };
    };

const TextareaControl = ({
    name,
    helpText,
    children,
    extendedText,
    ...props
}: TextareaControlProps) => {
    const [field, meta] = useField(name);
    const [isFocused, setFocused] = React.useState(false);
    const [value, setValue] = React.useState("");
    const onFocus = () => setFocused(!isFocused);
    const onBlur = () => setFocused(false);
    const handleChange = (el: any) => setValue(el.target.value);
    const errorTouched = meta.error && meta.touched;
    const addErrorClass = errorTouched ? "error " : "";
    const toggleClass = isFocused || value ? addErrorClass + "active" : "";

    return (
        <FormControl
            id={name}
            isInvalid={errorTouched ? true : false}
            isRequired={props.isRequired}
            position='relative'
            lineHeight='none'
            overflow='hidden'
            mt={4}
            _hover={{
                "label:not(.active)": {
                    color: "deepblue.500",
                },
            }}
            {...field}
        >
            <Textarea
                minH='300px'
                color='white'
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleChange}
                className={toggleClass}
                name={name}
                {...props}
            />

            <FormErrorMessage mt={2}>
                <ErrorMessage name={name} />
            </FormErrorMessage>
            {!errorTouched && helpText && (
                <FormHelperText
                    as='span'
                    fontSize='sm'
                    pl={7}
                    display='inline-block'
                >
                    *{helpText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
export default TextareaControl;
